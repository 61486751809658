// App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Research from './pages/Research';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';
import Coscphysresearch2024 from './pages/research/2024/computational-vision-force-estimation';

import SmartVitale from './pages/projects/hs/smartvitale';

function App() {
  return (
    <div className='flex flex-col h-fit'>
      <div className='App dark:bg-neutral-950 bg-white mt-[60px] h-fit'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/research" element={<Research />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects/hs/smartvitale" element={<SmartVitale />} />
          <Route path="/research/2024/computational-vision-force-estimation" element={<Coscphysresearch2024 />} />
        </Routes>
      </div>
      <div className='Footer bg-black text-white flex justify-between py-4'>
        <p className='font-hassText55 text-sm p-4'>&copy; 2024 Alexandre David, Wyoming</p>
        <div className='flex space-x-10 p-4 mr-10'>
          <div className='flex flex-col space-y-2'>
            <p className='font-hassText55 text-sm font-bold'>Contact:</p>
            <p className='font-hassText55 text-sm'>Email: <a href='mailto:uw.abrunet@gmail.com' target='_blank' rel="noreferrer noopener" className='mt-[2px] text-white hover:border-b-2 hover:border-white border-b-2 border-black'>uw.abrunet@gmail.com</a></p>
            <p className='font-hassText55 text-sm'>Phone: <a href='tel:+1-307-256-2020' target='_blank' rel="noreferrer noopener" className='mt-[2px] text-white hover:border-b-2 hover:border-white border-b-2 border-black'>+1 (307)256-2020</a></p>
          </div>
          <div className='flex flex-col space-y-2'>
            <p className='font-hassText55 text-sm font-bold'>Social:</p>
            <a href="https://www.linkedin.com/in/alexandre-brunet-40b217297/" target="_blank" rel="noreferrer noopener">Alexandre Brunet</a>
            <p className='font-hassText55 text-sm'>GitHub: <a href='https://github.com/xanderbrunet' target='_blank' rel="noreferrer noopener" className='mt-[2px] text-white hover:border-b-2 hover:border-white border-b-2 border-black'>xanderbrunet</a></p>
            <p className='font-hassText55 text-sm'>Twitter: </p>
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;
