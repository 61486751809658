import React, { useState } from 'react';
import { Link } from 'react-scroll';
import Tooltip from './../../../components/Tooltip';
import "./../../../styles/output.css";

function Coscphysresearch2024() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState(null);
    const [isAiModalOpen, setIsAiModalOpen] = useState(false); // New state for AI modal
    const [isLoaderModalOpen, setIsLoaderModalOpen] = useState(false); // New state for loader modal
    const [loaderText, setLoaderText] = useState('Connecting...'); // State for loader text

    const openModal = (imageUrl) => {
        setModalImageUrl(imageUrl);
        setIsModalOpen(true);
    };

    const handleCloseAndOpenLoader = () => {
        closeModal();
        setTimeout(openLoaderModal, 500); // Simulate loading time
    };

    const openAiModal = () => {
        setIsAiModalOpen(true); // Open AI modal
    };

    const openLoaderModal = () => {
        setIsLoaderModalOpen(true); // Open loader modal
        setTimeout(() => {
            setLoaderText('Creating Virtual Environment...'); // Set initial loader text
            setTimeout(() => {
                setLoaderText('Loading ForceEngine Model 0.1.1b...');
                setTimeout(() => {
                    setLoaderText('Starting Video Feed...');
                    navigator.mediaDevices.getUserMedia({ video: true })
                    setTimeout(() => {
                        closeModal(); // Close loader modal
                        setLoaderText('Connecting...'); // Reset loader text
                    }, 2000);
                }, 3000);
            }, 5000);
        }, 1500);

    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsAiModalOpen(false);
        setIsLoaderModalOpen(false);
        setModalImageUrl(null);
    };

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    return (
        <div className="flex w-full">
            {/* Sticky Sidebar Menu */}
            <div className="sticky mt-[60px] h-full px-6 py-2 sm:block hidden">
                <ul className="space-y-3">
                    <Tooltip tooltipText="Run the AI simulation in your browser">
                        <button onClick={openAiModal} className="cursor-pointer text-white bg-blue-800 rounded-md py-1 px-2 ml-[-8px] hover:bg-blue-900">
                            Run Simulation
                        </button>
                    </Tooltip>
                    <li>
                        <Tooltip tooltipText="Go to Home">
                            <Link
                                activeClass="text-blue-500 font-semibold"
                                to="home"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={500}
                                className="cursor-pointer text-black dark:text-white hover:underline"
                            >
                                Home
                            </Link>
                        </Tooltip>
                    </li>
                </ul>
            </div>

            {/* Main Content */}
            <div className="Container w-3/5 ml-10 dark:bg-neutral-950 bg-white p-2">
                <div className='Banner w-fit bg-blue-500 h-[40px] text-white flex items-center p-2 gap-2 rounded-md'>
                    <svg className="WarningSymbol" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={'30px'} fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="8" x2="12" y2="12" />
                        <line x1="12" y1="16" x2="12" y2="16" />
                    </svg>
                    <p className='text-sm font-hassText55'>Work in progress.</p>
                </div>
                <h1 className="text-2xl mt-5 font-hassText55 text-black dark:text-white tracking-wide">
                    Leveraging Machine Learning for Real-Time Prediction of Physical Forces: Innovations in Computational Vision and Force Estimation
                </h1>
                <h3 className="mt-2 text-md font-hassText55 text-neutral-500">
                    Alexandre David, Trevor Swarm, Laramie County Community College
                </h3>

                {/* Purpose Section */}
                <div id="purpose">
                    <h1 className="mt-5 text-xl font-light font-hassDisplay75 text-neutral-600 dark:text-neutral-400 tracking-wide">Purpose:</h1>
                    <p className='mt-2 text-md font-hassText55 text-black dark:text-white max-w-prose leading-8'>
                        {/* Content */}
                    </p>
                </div>

                {/* Objectives Section */}
                <div id="objectives">
                    <h1 className="mt-5 text-xl font-light font-hassDisplay75 text-neutral-600 dark:text-neutral-400 tracking-wide">Objectives:</h1>
                    <p className='mt-2 text-md font-hassText55 text-black dark:text-white max-w-prose leading-8'>
                        {/* Content */}
                    </p>
                </div>             

                {/* Modal for Image */}
                {isModalOpen && (
                    <div 
                        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75" 
                        onClick={handleBackgroundClick}
                    >
                        <div className="relative bg-white dark:bg-black p-4 rounded-md">
                            <img 
                                src={modalImageUrl} 
                                alt='Modal Content' 
                                className='max-w-full max-h-full'
                            />
                            <button 
                                onClick={closeModal} 
                                className="absolute top-2 right-2 text-black dark:text-white text-2xl font-bold"
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                )}

                {/* Modal for AI Simulation */}
                {isAiModalOpen && (
                    <div 
                        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-75" 
                        onClick={handleBackgroundClick}
                    >
                        <div className="relative bg-white dark:bg-black p-5 rounded-md w-11/12 md:w-3/4 lg:w-1/2 h-3/4 thin-scroll overflow-auto">
                            <h2 className="text-lg font-semibold text-black dark:text-white mb-4">Use Agreement for AI-Based Camera Feed and Video Processing Tool</h2>
                            <p className='text-md font-hassText55 text-black dark:text-white'>
                                <strong>Effective Date:</strong> 08/24/2024
                            </p>
                            <p className='text-md font-hassText55 text-black dark:text-white mt-2'>
                                By accessing and using this AI-based tool for live camera feeds and video processing (the "Service"), you agree to the following terms and conditions. Please read this agreement carefully.
                            </p>
                            <ol className='list-decimal pl-5 mt-4 text-md font-hassText55 text-black dark:text-white'>
                                <li><strong>Acceptance of Terms:</strong> By using the Service, you agree to comply with and be bound by these terms. If you do not agree to these terms, please do not use the Service.</li>
                                <li><strong>Description of the Service:</strong> The Service provides a platform for processing live camera feeds using artificial intelligence (AI) to predict physical forces and perform related video processing tasks. Video feeds are captured from your device and transmitted to our servers for processing. The Service is accessible via https://adavid03.com.</li>
                                <li><strong>Use of the Service:</strong>
                                    <ul className='list-disc pl-5 mt-2'>
                                        <li><strong>Eligibility:</strong> You must be at least 18 years old or have the consent of a parent or guardian to use the Service.</li>
                                        <li><strong>Permitted Use:</strong> You may use the Service for lawful purposes only. You agree not to use the Service in any way that could damage, disable, or impair the functionality of the Service or interfere with other users' access.</li>
                                    </ul>
                                </li>
                                <li><strong>Privacy and Data Collection:</strong>
                                    <ul className='list-disc pl-5 mt-2'>
                                        <li><strong>Data Collection:</strong> Video feeds and related data are transmitted to and processed on our servers. This data is used to run the AI models and generate predictions. We may also collect additional data related to your use of the Service.</li>
                                        <li><strong>Privacy Policy:</strong> Please review our <a className='underline cursor-pointer'>Privacy Policy</a> to understand how we collect, use, and protect your data. By using the Service, you consent to our data practices as described in the Privacy Policy.</li>
                                    </ul>
                                </li>
                                <li><strong>Intellectual Property:</strong>
                                    <ul className='list-disc pl-5 mt-2'>
                                        <li><strong>Ownership:</strong> All content, software, and technology used in the Service are the property of Alexandre David, Trevor Swarm and the Laramie County Community College or its licensors and are protected by intellectual property laws.</li>
                                        <li><strong>License:</strong> We grant you a limited, non-exclusive, non-transferable license to use the Service for its intended purposes. You may not copy, modify, distribute, or create derivative works from the Service without our explicit permission.</li>
                                    </ul>
                                </li>
                                <li><strong>Limitations and Disclaimers:</strong>
                                    <ul className='list-disc pl-5 mt-2'>
                                        <li><strong>Accuracy:</strong> While we strive to provide accurate predictions and reliable service, we do not guarantee the accuracy, completeness, or reliability of the results.</li>
                                        <li><strong>No Warranty:</strong> The Service is provided "as is" without warranties of any kind. We disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.</li>
                                    </ul>
                                </li>
                                <li><strong>Liability:</strong>
                                    <ul className='list-disc pl-5 mt-2'>
                                        <li><strong>Limitation of Liability:</strong> In no event shall Alexandre David, Trevor Swarm or the Laramie County Community College be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Service.</li>
                                        <li><strong>Indemnification:</strong> You agree to indemnify and hold harmless Alexandre David, Trevor Swarm or the Laramie County Community College from any claims, liabilities, damages, or expenses arising out of your use of the Service.</li>
                                    </ul>
                                </li>
                                <li><strong>Changes to the Agreement:</strong> We may update these terms from time to time. Changes will be posted on this page, and your continued use of the Service constitutes acceptance of the updated terms.</li>
                                <li><strong>Termination:</strong> We reserve the right to terminate or suspend your access to the Service at our discretion, without notice, if you violate these terms or engage in any prohibited activities.</li>
                                <li><strong>Contact Information:</strong> For questions or concerns regarding this agreement, please contact us at help@adavid03.com.</li>
                                <li><strong>Governing Law:</strong> This agreement shall be governed by and construed in accordance with the laws of Wyoming, United States of America, without regard to its conflict of law principles.</li>
                            </ol>
                            <p className='text-md font-hassText55 text-black dark:text-white mt-4'>
                                By using the Service, you acknowledge that you have read, understood, and agree to be bound by these terms.
                            </p>
                            <div className='flex justify-center mt-4 gap-2'>
                                <button
                                    onClick={closeModal}
                                    className="cursor-pointer text-white bg-red-800 rounded-md py-1 px-2 ml-2 hover:bg-red-900"
                                >
                                    I Disagree
                                </button>
                                <button 
                                    onClick={handleCloseAndOpenLoader}
                                    className="cursor-pointer text-white bg-blue-800 rounded-md py-1 px-2 hover:bg-blue-900"
                                >
                                    I Agree
                                </button>
                            </div>
                            <button 
                                onClick={closeModal} 
                                className="fixed top-2 right-2 text-black dark:text-white text-2xl font-bold"
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                )}

                {/* Loader Modal */}
                {isLoaderModalOpen && (
                    <div 
                        className="fixed inset-0 flex flex-col items-center justify-center z-50 bg-black bg-opacity-75"
                    >
                        <div className="animate-spin rounded-full h-10 w-10 border-t-5 border-b-2 border-red-500"></div>
                        <p className="text-neutral-500 font-hassText55 mt-5" id='loaodingmodaltext'>{loaderText}</p>
                    </div>
                )}

            </div>
        </div>
    );
}

export default Coscphysresearch2024;
