import "../styles/output.css";

function Home() {
    return (
        <div className="flex flex-col">
            <div className="flex items-center justify-center w-screen h-[calc(100vh-60px)]">
                <h1 className="text-8xl text-center font-hassDisplayBlack text-black dark:text-white tracking-wide mt-[-100px]">
                    ALEXANDRE DAVID
                </h1>
            </div>
            <div className="absolute mt-[75vh] flex items-center justify-center w-screen">
                <div className="mouse"></div>
            </div>
        </div>
    );
}

export default Home;